<script setup lang="ts">
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { Line } from 'vue-chartjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
);

interface Props {
  labels: string[];
  data: number[];
  label: string;
  borderColor: string;
  pointBackgroundColor: string;
  title?: string;
}

const props = defineProps<Props>();

// Helper function to create a semi-transparent color
const createSemiTransparentColor = (hexColor: string, alpha: number) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const chartData = computed(() => ({
  labels: props.labels,
  datasets: [
    {
      label: props.label,
      data: props.data,
      borderColor: props.borderColor,
      pointBackgroundColor: props.pointBackgroundColor,
      fill: {
        target: 'origin',
        above: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(
            0,
            0,
            0,
            context.chart.height,
          );
          gradient.addColorStop(0, props.borderColor);
          gradient.addColorStop(
            0.5,
            createSemiTransparentColor(props.borderColor, 0.1),
          );
          gradient.addColorStop(
            1,
            createSemiTransparentColor(props.borderColor, 0.05),
          );
          return gradient;
        },
      },
      tension: 0,
    },
  ],
}));

const options = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    filler: {
      propagate: false, // Prevent fill from propagating to other datasets
    },
  },
  scales: {
    x: {
      adapters: {
        date: {
          locale: enUS,
        },
      },
      type: 'time',
      time: {
        tooltipFormat: "MMMM d', 'yyyy",
        unit: 'day',
      },
      ticks: {
        font: {
          size: 10,
        },
      },
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        font: {
          size: 10,
        },
      },
    },
  },
}));
</script>

<template>
  <div>
    <Line :data="chartData" :options="options" />
  </div>
</template>
